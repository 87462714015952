<template>
  <v-container fluid dark>
    <v-layout row wrap>
      <v-flex class="container-bg" xs12 sm6>
        <div class="content-register">
          <img style="width:120px; margin-bottom: 20px"
            src="https://res.cloudinary.com/freddyci/image/upload/v1678128741/bjjlink-logo-8866ff_ppbegv.svg" />
          <h2 class="header my-2">{{ $t('message.login_page') }}</h2>
          <p class="eco-text">
            {{ $t('message.login_subtext') }}
          </p>
          <div class="form-container">
            <v-form v-model="valid" ref="form" lazy-validation class="ma-8 pa-12 reg-form-bg">
              <v-text-field label="Email" v-model="email" :rules="emailRules" required outlined dense
                dark></v-text-field>
              <v-text-field type="password" :label="$t('message.password')" v-model="password" v-on:keyup="captureEnter"
                required dense outlined dark></v-text-field>
              <div class="error" v-html="error" />
              <div v-if="isSmallerDisplay()">
                <v-btn ref="loginButton" class="elevation-0 regbtn pa-6" @click="login" block :disabled="!valid">
                  {{ $t('message.login') }}
                </v-btn>
              </div>
              <v-card-actions v-else>
                <v-spacer></v-spacer>
                <v-btn ref="loginButton" class="elevation-0 regbtn pa-6" @click="login" :disabled="!valid">
                  {{ $t('message.login') }}
                </v-btn>
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-form>
          </div>
          <div>
            <p class="newtext">{{$t("message.dont_have_account")}} <a @click="goToRegister()"
                style="color:#8866ff">{{$t("message.register_here")}}</a></p>
            <p class="newtext">{{$t("message.forgot_password")}} <a
                @click="showForgotPasswordDialog()">{{$t("message.click_here")}}</a></p>
          </div>
        </div>
      </v-flex>
      <v-flex xs12 sm6 style="height: 100vh; position: relative;">
        <v-img src="https://res.cloudinary.com/freddyci/image/upload/v1678246130/grid_0_wtuc06.png" aspect-ratio="1.5"
          height="100%"></v-img>
        <v-card class="overlay-card" elevation="5">
          <v-card-text>
            <h2 class="text-center eco-header">{{ $t('message.ecosystem_welcome') }}</h2>
            <p class="text-center eco-text">{{ $t('message.ecosystem_description') }}</p>
          </v-card-text>
          <v-container xs12 style="text-align: center;">
            <v-row class="white--text" :align="'start'" no-gutters>
              <v-col>
                <p class="">Admin +</p>
                <img src="https://res.cloudinary.com/freddyci/image/upload/v1676666366/logo-admin_rjh9pm.png"
                  style="width:85px" alt="Logo 1">
                <p class="text-caption" style="padding:0;height:85px;">{{ $t('message.admin_d') }}
                </p>
                <v-btn style="background-color:#0D1117; color: #f5f5f5;" elevation="0" class="mb-6"
                  @click="admin_dialog = true">{{ $t('message.more_info') }}</v-btn>
              </v-col>
              <v-col height="400">
                <p class="">Elevate</p>
                <img src="https://res.cloudinary.com/freddyci/image/upload/v1677893367/elevate-logo_bhxwz0.svg"
                  style="width:85px" alt="Logo 2">
                <p class="text-caption" style="padding:0;height:85px;">{{ $t('message.elevate_d') }}
                </p>
                <v-btn style="background-color:#0D1117; color: #f5f5f5;" elevation="0" class="mb-6"
                  @click="elevate_dialog = true">{{ $t('message.more_info') }}</v-btn>
              </v-col>
              <v-col>
                <p class="">Connect</p>
                <img :src="assets('logos/logo_connect.png')" style="width:96px;height:91px;" alt="Logo 3">
                <p class="text-caption" style="height:85px;margin-top:-5px; padding:0;">
                  {{ $t('message.connect_d') }}</p>
                <v-btn style="background-color:#0D1117; color: #f5f5f5;" elevation="0" class="mb-6"
                  @click="connect_dialog = true">{{ $t('message.more_info') }}</v-btn>
              </v-col>
            </v-row>
            <v-card-actions>
              <v-spacer></v-spacer>
              <img @click="
            goToLink(
              'https://play.google.com/store/apps/details?id=com.bjjlink.android'
            )
            " style="max-width: 150px; margin-right:6px; height: auto; cursor: pointer"
                :src="assets('googleplay_badge.png')" />
              <img @click="
            goToLink('https://apps.apple.com/us/app/bjjlink/id1458988543')
            " style="max-width: 150px; height: auto; cursor: pointer" :src="assets('appstore_badge.png')" />
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-container>
        </v-card>
      </v-flex>
    </v-layout>
    <div>
      <v-dialog v-model="admin_dialog" max-width="600">
        <v-card class="reg-form-bg">
          <v-card-title class="truncate eco-title">
            {{ $t('message.admin_title') }}
          </v-card-title>
          <v-card-text class="platform-content">
            <v-container>
              <v-row>
                <v-col cols="6">
                  <v-expand-x-transition>
                    <img :expand="true"
                      src="https://res.cloudinary.com/freddyci/image/upload/v1678829122/register_page/admin_hero-beta_qsfkh1.png"
                      width="100%" alt="Placeholder Image 1" style="border-radius: 10px;">
                  </v-expand-x-transition>
                </v-col>
                <v-col cols="6">
                  <img
                    src="https://res.cloudinary.com/freddyci/image/upload/v1678829139/register_page/payments_gbjgdl.png"
                    width="100%" alt="Placeholder Image 2" style="border-radius: 10px;">
                </v-col>
                <v-col cols="6">
                  <img
                    src="https://res.cloudinary.com/freddyci/image/upload/v1678829135/register_page/analytics_uovdat.png"
                    width="100%" alt="Placeholder Image 2" style="border-radius: 10px;">
                </v-col>
                <v-col cols="6">
                  <img
                    src="https://res.cloudinary.com/freddyci/image/upload/v1678829223/register_page/new-lms1_nuojxl.png"
                    width="100%" alt="Placeholder Image 3" style="border-radius: 10px;">
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <p class="eco-p">{{ $t('message.admin_description') }}</p>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-btn elevation="0" color="secondary" @click=" admin_dialog = false">Close</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="elevate_dialog" max-width="600">
        <v-card class="reg-form-bg">
          <v-card-title class="truncate eco-title">
            {{ $t('message.elevate_title') }}
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="6">
                  <img
                    src="https://res.cloudinary.com/freddyci/image/upload/v1678838468/register_page/elevate2_w508cd.png"
                    width="100%" alt="Placeholder Image 1" style="border-radius: 10px;">
                </v-col>
                <v-col cols="6">
                  <img
                    src="https://res.cloudinary.com/freddyci/image/upload/v1678838467/register_page/elevate1_lqhocg.png"
                    width="100%" alt="Placeholder Image 2" style="border-radius: 10px;">
                </v-col>
                <v-col cols="6">
                  <img
                    src="https://res.cloudinary.com/freddyci/image/upload/v1678838858/register_page/elevate3_m8xwzs.png"
                    width="100%" alt="Placeholder Image 2" style="border-radius: 10px;">
                </v-col>
                <v-col cols="6">
                  <img
                    src="https://res.cloudinary.com/freddyci/image/upload/v1678838867/register_page/elevate4_vpmvkr.png"
                    width="100%" alt="Placeholder Image 3" style="border-radius: 10px;">
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <p class="eco-p">{{ $t('message.elevate_description') }}</p>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-btn elevation="0" color="secondary" @click=" elevate_dialog = false">Close</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="connect_dialog" max-width="600">
        <v-card class="reg-form-bg">
          <v-card-title class="eco-title">
            {{ $t('message.connect_title') }}
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12" style="text-align: center;">
                  <img
                    src="https://res.cloudinary.com/freddyci/image/upload/v1678839473/register_page/connect-phone_lmawox.png"
                    width="100%" alt="Placeholder Image 1" style="border-radius: 10px; width: 160px;">
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <p class="eco-p">{{ $t('message.connect_description') }}</p>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-btn elevation="0" color="secondary" @click="connect_dialog = false">Close</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="showSendEmailDialog" max-width="500px">
        <v-card outlined class="pa-2">
          <v-card-text class="text-left" v-html="$t('message.password_recovery_info', [email])">
          </v-card-text>
          <v-card-actions>
            <v-btn small elevation="0" color="primary" @click.stop="sendResetInstructions()">{{
              $t('message.send_instructions') }}</v-btn>
            <v-btn small elevation="0" color="secondary" @click.stop="showSendEmailDialog = false">{{
              $t('message.close') }}</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
  </v-container>
</template>
<script>

import AuthenticationService from "@/services/AuthenticationService";
import AgreementService from "@/services/AgreementService";


export default {
data() {
  return {
    admin_dialog: false,
    elevate_dialog: false,
    connect_dialog: false,
    termsUrl: null,
    inactiveUser: this.$route.query.inactive,
    to: this.$route.query.to,
    specialRoute: null,
    email: "",
    password: "",
    image: null,
    error: "",
    valid: true,
    loginPayload: null,
    emailRules: [
      v => !!v || "E-mail is required",
      v =>
        /^(\s)*[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*(\s)*$/.test(v) ||
        this.$t("message.invalid_email")
    ],
    showSendEmailDialog: false,
    isConnected: false,
  };
},
components: {

},
created(){
  AgreementService.latest()
    .then(data => {
      if (data) {
        this.loading = false;
        this.termsUrl = data.url;
      }
    });
  this.setLang();
  this.isAppDisplay();
  if(this.$route.query.specialroute)
    this.specialRoute = this.$route.query.specialroute;
  if(this.$route.params.justRegistered)
    this.newRegisterAlert();
},
methods: {
  showForgotPasswordDialog(){
    if(!this.email){
      this.showAlert(
        "info",
        this.$t('message.type_email')
      );
    }else{
      this.showSendEmailDialog = true;
    }
  },
  newRegisterAlert(){
    this.showAlert(
        "success",
        "Welcome!",
        "Now, LOGIN and start connecting your Jiu Jitsu journey.."
      );
  },
  goToRegister() {
    if (this.to == 'elevate')
      window.location = "/register?to=elevate";
    if (this.to == 'admin')
        window.location = "/register?to=admin";
    else
      window.location = "/register";
  },
  captureEnter(e) {
    if (e.keyCode === 13) {
      this.login();
    }
  },
  async sendResetInstructions() {
    AuthenticationService.forgotPassword({ email: this.email })
      .then(data => {
        if (data) {
          this.showSendEmailDialog = false;
          this.showAlert("success", this.$t('message.email_sent_to', [this.email])+' '+this.$t('message.check_spam'));
        }
      })
      .catch(err => {
        this.showAlert("error", err.response.data.message);
      });
  },
  async login() {
    try {
      this.error = "";
      const response = await AuthenticationService.login({
        email: this.email.trim(),
        password: this.password
      });
      this.processLoggedIn(response);
    } catch (err) {
      if (err.response.status == 404) 
        this.error = this.$t("message.invalid_user");
      else if (err.response.status == 400)
        this.error = this.$t("message.invalid_password");
      else this.error = this.$t("message.error_generic");
    }
  },
  processLoggedIn(response) {
    if (response.data.token) {
        this.$store.dispatch("setToken", response.data.token);
        this.$store.dispatch("setUser", response.data.user);
        this.$store.dispatch("setName", response.data.user.fName);
        this.$store.dispatch("setHidden", response.data.user.hidden);
        this.$store.dispatch("setInApp", false);
        this.$store.dispatch("setUserImage", 
          (response.data.user.userImageSmall ? 
          response.data.user.userImageSmall : 
          response.data.user.userImage));
        this.$store.dispatch("setLanguage", response.data.user.lang);
        this.$i18n.locale = response.data.user.lang;
        localStorage.token = response.data.token;
        if (
          response.data.hasLatestAgreement &&
          response.data.hasLatestAgreement == true
        ) {
          localStorage.hasAgreements = true;
          this.$store.dispatch("setHasAgreements", true);
        } else {
          localStorage.hasAgreements = false;
          this.$store.dispatch("setHasAgreements", false);
        }
        if(this.specialRoute && this.specialRoute == 'checkin')
          window.location = '/admin/secure/pickacademy'
        else if (this.to == 'elevate')
          window.location = '/elevate'
        else if (this.to == 'admin')
          window.location = '/admin'
        else if (
          response.data.user.totalHistory &&
          response.data.user.totalHistory > 0
        )
          window.location = '/secure/home?login=true'
        else 
          window.location = '/secure/history'
      } else {
        this.error = 'Error';
      }
  }
},
mounted() {
  this.$refs.loginButton.$el.focus();
},
computed: {
  panelSize() {
    if (
      this.$vuetify.breakpoint.name == "xs" ||
      this.$vuetify.breakpoint.name == "sm"
    )
      return "100%";
    else return "50%";
  }
}
};
</script>

<style scoped>

.v-card__title{
  word-break: break-word;
}
.container-bg {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: black;
}

.content-register {
  width: 600px;
  text-align: center;
}

.top-title {
  font-family: 'Plus Jakarta Sans', sans-serif !important;
}

.eco-header {
  font-family: 'Plus Jakarta Sans', sans-serif !important;
  font-size: 1.795rem;
  font-weight: 500;
  line-height: 1.2;
  color: #fff !important;
  text-align: center;
}

.eco-title {
  font-family: 'Plus Jakarta Sans', sans-serif !important;
  font-size: 1.795rem;
  font-weight: 500;
  line-height: 1.2;
  color: #fff !important;
}

.eco-text {
  font-family: 'Plus Jakarta Sans', sans-serif !important;
  font-size: 0.795rem;
  font-weight: 400;
  line-height: 1.4;
  color: #f5f5f5 !important;
  text-align: center;
  padding: 0 50px;
}

.eco-p {
  font-family: 'Plus Jakarta Sans', sans-serif !important;
  font-size: 0.795rem;
  font-weight: 400;
  line-height: auto;
  color: #f5f5f5 !important;
}

.registration_error {
  color: #8866ff;
}

.login-button {
  font-size: large;
  color: #8866ff;
}

.header {
  font-size: 1.495rem;
  font-weight: 500;
  text-align: center;
  font-family: 'Plus Jakarta Sans', sans-serif !important;
  color: white;
}

.newtext {
  font-size: 0.795rem;
  font-weight: 400;
  text-align: center;
  font-family: 'Plus Jakarta Sans', sans-serif !important;
  color: white;
}

.overlay-card {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 500px;
  width: 100%;
  padding: 20px;
  background-color: rgba(0, 0, 0, 0.7);
  border-radius: 10px;
}

.container-bg-tran {
  background-color: transparent !important;
}

.theme--dark .layout.wrap .container-bg {
  background: #0D1117 !important;
}

.reg-form-bg {
  background-color: #0D1117 !important;
  border-radius: 10px !important;
  padding: 10px;
  border: 1px solid #8866ff40 !important;
  box-shadow: 0 0 20px #8866ff10 !important;
}


.container-bg {
  background: #0D1117 !important;
}

.v-stepper__header {
  box-shadow: none !important;
}

.chev-color {
  background-color: #8866ff !important;
  color: #0D1117 !important;
}

.regbtn {
  background-color: #8866ff !important;
  color: #0D1117 !important;
  font-family: 'Plus Jakarta Sans', sans-serif !important;
  font-size: 16px;
  font-weight: 500 !important;
  letter-spacing: 0.5px !important;
}
.infobtn {
  background-color: #0D1117 !important;
  color: #8866ff !important;
  font-family: 'Plus Jakarta Sans', sans-serif !important;
  font-size: 16px;
  font-weight: 500 !important;
  letter-spacing: 0.5px !important;
}

.step-color {
  background-color: #8866ff !important;
  color: #0D1117 !important;
}
.truncate{
    word-break: break-word;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 4; /* adjust as needed */
    overflow: hidden;
}

@media only screen and (max-width: 767px) {
  .truncate{
    word-break: break-word;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 4; /* adjust as needed */
    overflow: hidden;
}
.eco-text{
  padding: 0 20px 0 20px;
}
.container-bg {
height: auto;
padding: 20px 0px 50px 0px;
}
.v-dialog > .v-card > .v-card__text.platform-content {
    padding: 0 0px 0px;
}
}

/* iPad */
@media only screen and (min-width: 768px) and (max-width: 1023px) {
  /* Insert styles for iPad devices here */
}

/* Desktop */
@media only screen and (min-width: 1024px) {
  /* Insert styles for desktop devices here */
}
</style>